<template>
  <div class="d-flex notification" :class="{ unseen: !notification.isSeen }">
    <div class="notification__icon-block mb-3">
      <HMPhotoIcon
        class="notification__icon"
        :instructor="getActor"
      />
      <div class="notification__sub-icon-bg d-flex align-items-center smaller">
        <i
          v-if="notification.verb === 'Comment'"
          class="notification__sub-icon fal fa-comment"
        ></i>
        <i
          v-if="notification.verb === 'Reply'"
          class="notification__sub-icon fal fa-comment"
        ></i>
        <i
          v-if="notification.verb === 'Mention'"
          class="notification__sub-icon fal fa-comment"
        ></i>
        <i
          v-if="notification.verb === 'Like'"
          class="notification__sub-icon fal fa-heart"
        ></i>
        <i
          v-if="notification.verb === 'Flag'"
          class="notification__sub-icon fal fa-exclamation-circle"
        ></i>
      </div>
    </div>
    <div class="ms-3">
      <span v-html="getText"></span>
      <div class="notification__date mt-1">
        {{ dateFromNow(notification.time) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from '@/services/moment';

export default {
  name: 'CommunityNotificationUI',
  props: {
    notification: {
      type: Object,
    },
  },
  computed: {
    extraData() {
      return this.notification?.extraData;
    },
    getFirstActorName() {
      const actor = this.notification.actor;
      if (!actor) return null;
      return this.getActorName(actor);
    },
    getDistinctActors() {
      if (!this.notification?.activities?.length) return [];
      return [...new Set(this.notification.activities.map(a => a.actor))];
    },
    getText() {
      /*
      "USER_COMMENTED_WITH_TITLE": "{user} commented on: »{title}«",
      "TWO_USERS_COMMENTED_WITH_TITLE": "{user1} and {user2} commented on: »{title}«",
      "MULTIPLE_USERS_COMMENTED_WITH_TITLE": "{user1}, {user2} and {allUsersCount} others commented on: »{title}«",
      "USER_LIKED_YOUR_EMPTY_COMMENT": "{user} liked your comment",
      "TWO_USERS_LIKED_YOUR_EMPTY_COMMENT": "{user1} and {user2} liked your comment",
      "MULTIPLE_USERS_LIKED_YOUR_EMPTY_COMMENT": "{user1}, {user2} and {allUsersCount} others liked your comment",
      "USER_LIKED_YOUR_COMMENT": "{user} liked your comment: »{comment}«",
      "TWO_USERS_LIKED_YOUR_COMMENT": "{user1} and {user2} liked your comment: »{comment}«",
      "MULTIPLE_USERS_LIKED_YOUR_COMMENT": "{user1}, {user2} and {allUsersCount} others liked your comment: »{comment}«",
      "USER_REPLIED_TO_YOUR_COMMENT_WITH_TITLE": "{user} replied to your comment on »{title}«",
      "TWO_USERS_REPLIED_TO_YOUR_COMMENT_WITH_TITLE": "{user1} and {user2} replied your comment on »{title}«",
      "MULTIPLE_USERS_REPLIED_TO_YOUR_COMMENT_WITH_TITLE": "{user1}, {user2} and {allUsersCount} others replied your comment on »{title}«"
      "USER_MENTIONED_IN_A_COMMENT": "{user} mentioned you in a comment on: »{title}«",
      "TWO_USERS_MENTIONED_IN_A_COMMENT": "{user1} and {user2} mentioned you in a comment on: »{title}«",
      "MULTIPLE_USERS_MENTIONED_IN_A_COMMENT": "{user1}, {user2} and {allUsersCount} mentioned you in a comment on: »{title}«"
      */
      const { verb, actorCount, activityCount } = this.notification;
      const actors = this.getDistinctActors;
      const notificationTitle =
        this.shortenText(this.extraData?.title) ||
        this.shortenText(this.extraData?.group_name);
      const text = this.extraData?.text;
      switch (verb) {
        case 'Like':
          if (actorCount === 1) {
            if (text) {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.USER_LIKED_YOUR_COMMENT',
                {
                  user: this.getActorName(actors[0]),
                  comment: this.shortenText(text),
                }
              );
            } else {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.USER_LIKED_YOUR_EMPTY_COMMENT',
                {
                  user: this.getActorName(actors[0]),
                }
              );
            }
          } else if (actorCount === 2) {
            if (text) {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.TWO_USERS_LIKED_YOUR_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                  comment: this.shortenText(this.extraData?.text),
                }
              );
            } else {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.TWO_USERS_LIKED_YOUR_EMPTY_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                }
              );
            }
          } else {
            if (text) {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_LIKED_YOUR_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                  allUsersCount: actorCount,
                  comment: this.shortenText(text),
                }
              );
            } else {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_LIKED_YOUR_EMPTY_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                  allUsersCount: actorCount,
                }
              );
            }
          }
        case 'Flag':
          if (actorCount === 1) {
            if (text) {
              return this.$t('COMMUNITY.NOTIFICATIONS.USER_FLAGGED_COMMENT', {
                user: this.getActorName(actors[0]),
                comment: this.shortenText(text),
              });
            } else {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.USER_FLAGGED_EMPTY_COMMENT',
                {
                  user: this.getActorName(actors[0]),
                }
              );
            }
          } else if (actorCount === 2) {
            if (text) {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.TWO_USERS_FLAGGED_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                  comment: this.shortenText(this.extraData?.text),
                }
              );
            } else {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.TWO_USERS_FLAGGED_EMPTY_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                }
              );
            }
          } else {
            if (text) {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_FLAGGED_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                  allUsersCount: actorCount,
                  comment: this.shortenText(text),
                }
              );
            } else {
              return this.$t(
                'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_FLAGGED_EMPTY_COMMENT',
                {
                  user1: this.getActorName(actors[0]),
                  user2: this.getActorName(actors[1]),
                  allUsersCount: actorCount,
                }
              );
            }
          }
        case 'Comment':
          if (actorCount === 1 && this.extraData?.title) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.USER_COMMENTED_WITH_TITLE',
              {
                user: this.getActorName(actors[0]),
                title: notificationTitle,
              }
            );
          } else if (actorCount === 1 && this.extraData?.group_name) {
            return this.$t('COMMUNITY.NOTIFICATIONS.USER_COMMENTED_IN_GROUP', {
              user: this.getActorName(actors[0]),
              title: notificationTitle,
            });
          } else if (actorCount === 2 && this.extraData?.title) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.TWO_USERS_COMMENTED_WITH_TITLE',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                title: notificationTitle,
              }
            );
          } else if (actorCount === 2 && this.extraData?.group_name) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.TWO_USERS_COMMENTED_IN_GROUP',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                title: notificationTitle,
              }
            );
          } else if (this.extraData?.group_name) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_COMMENTED_IN_GROUP',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                allUsersCount: actorCount,
                title: notificationTitle,
              }
            );
          } else {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_COMMENTED_WITH_TITLE',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                allUsersCount: actorCount,
                title: notificationTitle,
              }
            );
          }
        case 'Reply':
          if (actorCount === 1 && this.extraData?.title) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.USER_REPLIED_TO_YOUR_COMMENT_WITH_TITLE',
              {
                user: this.getActorName(actors[0]),
                title: notificationTitle,
              }
            );
          } else if (actorCount === 1 && this.extraData?.group_name) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.USER_REPLIED_TO_YOUR_COMMENT_IN_GROUP',
              {
                user: this.getActorName(actors[0]),
                title: notificationTitle,
              }
            );
          } else if (actorCount === 2 && this.extraData?.title) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.TWO_USERS_REPLIED_TO_YOUR_COMMENT_WITH_TITLE',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                title: notificationTitle,
              }
            );
          } else if (actorCount === 2 && this.extraData?.group_name) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.TWO_USERS_REPLIED_TO_YOUR_COMMENT_IN_GROUP',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                title: notificationTitle,
              }
            );
          } else if (this.extraData?.group_name) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_REPLIED_TO_YOUR_COMMENT_IN_GROUP',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                allUsersCount: actorCount,
                title: notificationTitle,
              }
            );
          } else {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_REPLIED_TO_YOUR_COMMENT_WITH_TITLE',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                allUsersCount: actorCount,
                title: notificationTitle,
              }
            );
          }
        case 'Mention':
          if (actorCount === 1) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.USER_MENTIONED_IN_A_COMMENT',
              {
                user: this.getActorName(actors[0]),
                title: notificationTitle,
              }
            );
          } else if (actorCount === 2) {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.TWO_USERS_MENTIONED_IN_A_COMMENT',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                title: notificationTitle,
              }
            );
          } else {
            return this.$t(
              'COMMUNITY.NOTIFICATIONS.MULTIPLE_USERS_MENTIONED_IN_A_COMMENT',
              {
                user1: this.getActorName(actors[0]),
                user2: this.getActorName(actors[1]),
                allUsersCount: actorCount,
                title: notificationTitle,
              }
            );
          }
      }
      return `Unknown verb: ${verb}`;
    },
    getActor() {
      if(this.notification.actor && typeof this.notification.actor === 'object') {
        return this.notification.actor;
      }
      return {}; 
    },
  },
  methods: {
    dateFromNow(date) {
      return moment(date).fromNow();
    },
    getActorName(actor) {
      if (!actor) return null;
      return actor.last_name
        ? `${actor.first_name} ${actor.last_name}`
        : `${actor.first_name}`;
    },
    shortenText(text, length = 20) {
      if (!text) return '';
      if (text.length < length) return text;

      return `${text.substring(0, length)}...`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

:deep(span.bold) {
  font-weight: 500;
}

.notification {
  padding: 7px;
  border-radius: 6px;
  background-color: transparent;

  &.unseen {
    background-color: $lightest-gray;
    :deep(span.bold) {
      font-weight: 600;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $light-gray;
  }
}

.notification__icon-block {
  position: relative;
  width: 48px;
  height: 48px;
}

.notification__icon {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.notification__sub-icon-bg {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  border: 2px solid $white;
  background-color: var(--brand-color, $coral);
  position: absolute;
  right: -5px;
  bottom: -10px;
  color: $white;
  text-align: center;
  line-height: 28px;
}

.notification__sub-icon {
  color: $white;
  left: 0;
  right: 0;
  position: absolute;
  font-weight: 500;
}

.notification__date {
  font-size: 12px;
  color: $dark-gray;
}
</style>
